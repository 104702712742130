import PageBuilderTextWidget from '@/components/PageBuilderTextWidget'
import PageBuilderHeadingWidget from '@/components/PageBuilderHeadingWidget'
import PageBuilderLineBreakWidget from '@/components/PageBuilderLineBreakWidget'
import PageBuilderDividerWidget from '@/components/PageBuilderDividerWidget'
import PageBuilderImageWidget from '@/components/PageBuilderImageWidget'
import PageBuilderCtaWidget from '@/components/PageBuilderCtaWidget'
import PageBuilderHeroWidget from '@/components/PageBuilderHeroWidget'
import PageBuilderFeatureWidget from '@/components/PageBuilderFeatureWidget'
import PageBuilderActionWidget from '@/components/PageBuilderActionWidget'
import PageBuilderGridWidget from '@/components/PageBuilderGridWidget'

export default function usePageBuilderWidgets() {
  function getPageBuilderWidgetByType(widgetType) {
    switch (widgetType) {
      case 'text':
        return PageBuilderTextWidget
      case 'heading':
        return PageBuilderHeadingWidget
      case 'lineBreak':
        return PageBuilderLineBreakWidget
      case 'divider':
        return PageBuilderDividerWidget
      case 'image':
        return PageBuilderImageWidget
      case 'cta':
        return PageBuilderCtaWidget
      case 'hero':
        return PageBuilderHeroWidget
      case 'feature':
        return PageBuilderFeatureWidget
      case 'action':
        return PageBuilderActionWidget
      case 'grid':
        return PageBuilderGridWidget
      default:
        return 'div'
    }
  }

  return {
    getPageBuilderWidgetByType,
  }
}
