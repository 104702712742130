<template>
  <div
    class="md:grid"
    :style="gridStyle"
  >
    <div
      v-for="(n,index) in value.value"
      :key="index"
    >
      <div data-test="grid-widget">
        <component
          :is="getPageBuilderWidgetByType(getWidgetByIndex(index).type)"
          :value="getWidgetByIndex(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import usePageBuilderWidgets from '@/composables/usePageBuilderWidgets'

export default {
  setup() {
    const { getPageBuilderWidgetByType } = usePageBuilderWidgets()
    return {
      getPageBuilderWidgetByType,
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gridStyle() {
      return {
        'grid-template-columns': `repeat(${this.value.config.column ||
          1}, 1fr)`,
      }
    },
  },
  methods: {
    getWidgetByIndex(index) {
      return (this.value.value || [])[index]
    },
  },
}
</script>
