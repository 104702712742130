<template>
  <div>
    <img
      :src="value.config.image || placeholder"
      :alt="value.config.alt"
      class="rounded-md max-w-full"
      :class="widgetClasses"
    />
  </div>
</template>

<script>
import placeholder from '@/assets/images/placeholder.jpg'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    placeholder: () => placeholder,
  },
}
</script>
