<template>
  <div>
    <a
      :class="{...configStyleClasses, ...widgetClasses}"
      :href="value.config.url"
      :target="value.config.newTab? '_blank' : '_self'"
      v-html="value.value"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    configStyleClasses() {
      return {
        button: this.value.config.type === 'button',
        link: this.value.config.type === 'link',
        block: !this.value.config.compact,
        'inline-block': this.value.config.compact,
      }
    },
  },
}
</script>
<style scoped>
.button {
  background: var(--theme) !important;
  color: #fff !important;
  border-radius: 3px;
  padding: 8px 16px;
}
.link {
  color: var(--theme) !important;
}
</style>
