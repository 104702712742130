<template>
  <div class="sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between p-4">
    <div class="flex-1 mr-4">
      <h2
        class="text-3xl leading-9 tracking-tight sm:text-4xl sm:leading-10"
        data-test="action-widget-heading"
        v-html="heading.value"
        />
        <p
        class="my-4 leading-6 text-gray-500"
        data-test="action-widget-text"
        v-html="body.value"
      />
    </div>
    <div class="flex items-center">
      <div class="flex">
        <div class="mt-8 flex lg:mt-0">
          <a
            v-for="(btn, i) in ctaButtons"
            :key="btn.id"
            :href="btn.config.url"
            :target="btn.config.newTab? '_blank' : '_self'"
            :class="getButtonClasses(i)"
            :data-test="`action-widget-button-${i}`"
            class="rounded-md shadow px-4 py-2 text-base leading-6 font-medium rounded-md transform hover:scale-105 transition duration-150 ease-in-out md:py-3 md:text-lg md:px-6 ml-2 mt-2 sm:mt-0"
          >
            <span v-html="btn.value" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('settings', ['settings']),
    heading() {
      return this.value.value.find((c) => c.type === 'heading')
    },
    body() {
      return this.value.value.find((c) => c.type === 'text')
    },
    ctaButtons() {
      return this.value.value.filter((c) => c.type === 'cta')
    },
  },
  methods: {
    getButtonClasses(index) {
      if (this.settings.theme === this.settings.themeLightest) {
        return {
          'text-white bg-theme-base': index === 0,
          'color-theme-base bg-transparent': index === 1,
        }
      } else {
        return {
          'color-theme-lightest bg-theme-base': index === 0,
          'color-theme-base bg-theme-lightest': index === 1,
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/contemporaryTheme/home.scss';
</style>
