<template>
  <br :class="widgetClasses" />
</template>

<script>
export default {
  props: {
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
