<template>
  <component
    :is="configElement"
    :class="{...configStyleClasses, ...widgetClasses}"
    :style="configStyles"
    class="mb-0"
    v-html="value.value"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    configStyles() {
      const textDecoration = `${
        this.value.config.underline ? 'underline' : ''
      } ${this.value.config.strikethrough ? 'line-through' : ''}`
      return {
        ...(textDecoration.trim() ? { 'text-decoration': textDecoration } : {}),
      }
    },
    configElement() {
      switch (this.value.config.size) {
        case 'small':
          return 'h3'
        case 'medium':
          return 'h2'
        case 'large':
          return 'h1'
        default:
          return 'h1'
      }
    },
    configStyleClasses() {
      return {
        'text-left': this.value.config.alignment === 'left',
        'text-center': this.value.config.alignment === 'center',
        'text-right': this.value.config.alignment === 'right',
        'font-bold': this.value.config.bold,
        'text-lg': this.value.config.size === 'small',
        'text-3xl': this.value.config.size === 'medium',
        'text-4xl': this.value.config.size === 'large',
      }
    },
  },
}
</script>
