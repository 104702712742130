<template>
  <hr class="my-4" />
</template>

<script>
export default {
  props: {
    widgetClasses: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
