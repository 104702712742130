<template>
  <div class="relative bg-theme-base overflow-hidden">
    <div class="max-w-screen-xl mx-auto">
      <div class="relative z-10 pb-8 bg-theme-base sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
          aria-hidden="true"
          class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 color-theme-base transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          role="img"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <div class="pt-20 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div class="sm:text-center lg:text-left">
            <div
              class="text-4xl tracking-tight leading-10 sm:text-5xl sm:leading-none md:text-6xl color-theme-lightest"
              v-html="heading.value"
            />
            <div
              v-if="subHeading"
              class="text-4xl tracking-tight leading-10 sm:text-5xl sm:leading-none md:text-6xl color-theme-lightest"
              v-html="subHeading.value"
            />
            <div
              class="mt-3 color-theme-lightest sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
              v-html="body.value"
            >
            </div>
            <div
              v-if="ctaButtons && ctaButtons.length"
              class="mt-5 sm:mt-8 flex sm:justify-center lg:justify-start"
            >
              <div
                v-for="(link,index) in ctaButtons"
                :key="index"
                class="mt-3 mr-3 flex"
              >
                <a
                  :href="link.config.url"
                  :target="link.config.newTab? '_blank' : '_self'"
                  :class="{'border border-transparent color-theme-base bg-theme-lightest':!index % 2, 'border-2 border-theme-lightest color-theme-lightest bg-transparent':index % 2}"
                  class="px-8 py-3 text-base leading-6 font-medium rounded-md transform hover:scale-105 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                  {{ link.value }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        :src="image.config.image || placeholder"
        :alt="image.config.alt"
      >
    </div>
  </div>
</template>

<script>
import placeholder from '@/assets/images/placeholder.jpg'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    heading() {
      return this.value.value[0]
    },
    subHeading() {
      return this.value.value[1]
    },
    body() {
      return this.value.value.find((c) => c.type === 'text')
    },
    image() {
      return this.value.value.find((c) => c.type === 'image')
    },
    ctaButtons() {
      return this.value.value.filter((c) => c.type === 'cta')
    },
    placeholder: () => placeholder,
  },
}
</script>

<style lang="scss">
@import '@/assets/css/contemporaryTheme/home.scss';
</style>
