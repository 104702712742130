<template>
  <div class="flex p-4">
    <img
      :src="image.config.image || placeholder"
      :alt="image.config.alt"
      data-test="feature-widget-image"
      class="image-icon rounded mr-4"
    >
    <div class="flex-1">
      <h3
        class="text-lg leading-6"
        v-html="heading.value"
      />
      <p
        class="my-2 leading-6 text-gray-500"
        v-html="body.value"
      />
      <a
        class="color-theme-base"
        :href="ctaButton.config.url"
        :target="ctaButton.config.newTab? '_blank' : '_self'"
        v-html="ctaButton.value"
      />
    </div>
  </div>
</template>

<script>
import placeholder from '@/assets/images/placeholder.jpg'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    heading() {
      return this.value.value.find((c) => c.type === 'heading')
    },
    body() {
      return this.value.value.find((c) => c.type === 'text')
    },
    image() {
      return this.value.value.find((c) => c.type === 'image')
    },
    ctaButton() {
      return this.value.value.find((c) => c.type === 'cta')
    },
    placeholder: () => placeholder,
  },
}
</script>

<style lang="scss">
@import '@/assets/css/contemporaryTheme/home.scss';
</style>

<style scoped>
.image-icon {
  width: 48px;
  height: 48px;
}
</style>
